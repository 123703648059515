import React, { useState } from "react"
import Section from "../../components/section/section"
import styled from "styled-components"
import Tiger from "../../../static/news/tiger_news_section.png"
import Hidden from "@material-ui/core/Hidden"

import BlogItemMobile from "./blog-item-mobile"
import Container from "@material-ui/core/Container"

import ArrowRight from "@material-ui/icons/ChevronRight"
import ArrowLeft from "@material-ui/icons/ChevronLeft"
import IconButton from "@material-ui/core/IconButton"
import ColorButton from "../buttons/color-button"
import Chip from "@material-ui/core/Chip"
import { Link } from "gatsby"

import { CSSTransition, TransitionGroup } from "react-transition-group"

import Img from "gatsby-image"

import("./blog-overview.css")

const LatestNewsBackground = styled.div`
font-family: Lobster;
font-weight: normal;
font-size: 500px;
letter-spacing: 0.05em;
line-height: 209px;
text-align: left;
color: #fff;
opacity: 0.1;
position: absolute;
left: 0
right: 0
`

const NewsContainer = styled.div`
  background-color: rgba(43, 40, 35, 1);
  width: 70%;
  height: 500px;
  position: absolute;
  bottom: 5%;
  right: 5%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const BlogImageContainer = styled.div`
  width: 40%;
  position: absolute;
  left: -30%;
  bottom: 10%;
  display: flex;
  align-items: center;
  background-color: white;
  -webkit-box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.75);
`

const NewsTitle = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0.05em;
  line-height: 30px;
  text-align: left;
  color: #fff;
  opacity: 0.9;
  padding-bottom: 20px;
`

const NewsText = styled.div`
  width: 65%;
  height: 45%;
  max-width: 600px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 15px;
  text-align: left;
  color: #fff;
  opacity: 0.7;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ButtonContainer = styled.div`
  border-radius: 0px 6px 0px 0px;
  background: linear-gradient(#1e4778 0%, #8dbdd9 100%);
  box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.32);
  position: absolute;
  bottom: 0;
`

const BlogOverviewDesktop = props => {
  const [activeBlog, changeBlog] = useState(0)
  const prevPage = () => {
    changeBlog(activeBlog - 1)
  }

  const nextPage = () => {
    changeBlog(activeBlog + 1)
  }

  return (
    <div>
      <Section
        center={
          <div>
            <NewsContainer>
              <img
                src={Tiger}
                style={{
                  height: "70%",
                  width: "auto",
                  position: "absolute",
                  right: "-0",
                  bottom: 0,
                  opacity: 0.5,
                }}
                alt="Tigase news"
              />
              <TransitionGroup>
                <CSSTransition
                  key={activeBlog}
                  timeout={1000}
                  classNames="messageout"
                >
                  <BlogImageContainer>
                    <div style={{ width: "100%" }}>
                      <Img
                        fluid={
                          props.posts[activeBlog].node.frontmatter.cover
                            .children[0].fluid
                        }
                      />
                    </div>
                  </BlogImageContainer>
                </CSSTransition>
              </TransitionGroup>
              <LatestNewsBackground>{'"'}</LatestNewsBackground>
              <NewsText>
                <NewsTitle>
                  {props.posts[activeBlog].node.frontmatter.title}
                  <p>{props.posts[activeBlog].node.frontmatter.date}</p>
                  {props.posts[activeBlog].node.frontmatter.tags.map(item => {
                    return (
                      <Link to={`/blog/tags/${item}`} key={item}>
                        <Chip
                          size="small"
                          clickable
                          label={item}
                          style={{ margin: 5 }}
                        />
                      </Link>
                    )
                  })}
                </NewsTitle>
                {props.posts[activeBlog].node.excerpt}
                <Link to={props.posts[activeBlog].node.fields.slug}>
                  <ColorButton
                    style={{ margin: 10 }}
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    Read More
                  </ColorButton>
                </Link>
              </NewsText>
              <ButtonContainer>
                <IconButton
                  disabled={activeBlog === 0 ? true : false}
                  style={{ color: "white" }}
                  onClick={prevPage}
                >
                  <ArrowLeft />
                </IconButton>
                <IconButton
                  disabled={
                    activeBlog === props.posts.length - 1 ? true : false
                  }
                  style={{ color: "white" }}
                  onClick={nextPage}
                >
                  <ArrowRight />
                </IconButton>
              </ButtonContainer>
            </NewsContainer>
          </div>
        }
      />
    </div>
  )
}

const NewsTextMobile = styled.div`
  font-family: "Fira Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  text-align: left;
  color: #454d62;
  margin: 20px;
`

export const BlogOverviewMobile = props => {
  return (
    <Container>
      <NewsTextMobile>Latest News</NewsTextMobile>
      <div>
        {props.posts.map((item, index) => {
          return (
            <BlogItemMobile
              key={index}
              post={item.node}
              img={
                <Img
                  fluid={item.node.frontmatter.cover.children[0].fluid}
                  alt="Blog news"
                />
              }
            />
          )
        })}
      </div>
    </Container>
  )
}

const BlogOverview = props => {
  return (
    <React.Fragment>
      <Hidden only={"xs"}>
        <BlogOverviewDesktop posts={props.posts} />
      </Hidden>
      <Hidden smUp>
        <BlogOverviewMobile posts={props.posts} />
      </Hidden>
    </React.Fragment>
  )
}

export default BlogOverview
