import { withStyles } from "@material-ui/core/styles"
import { blue } from "@material-ui/core/colors"
import Button from "@material-ui/core/Button"

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blue[100]),
    backgroundColor: blue[100],
    "&:hover": {
      backgroundColor: blue[300],
    },
    display: "flex",
  },
}))(Button)

export default ColorButton
